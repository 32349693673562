import { formatString } from "@/helpers/formatting";
import type { IPaginatedResponse } from "@/models/common";
import type {
  GetFundersPayload,
  IFunder,
  IFunderStatistic,
  IOffer
} from "@/models/funders";
import type { IUser } from "@/models/users";
import { axiosClient } from "../client";
import endpoints from "../endpoints";

class FundersApiService {
  async createFunder(data: Partial<IFunder>) {
    const response = await axiosClient.post<{ data: IFunder }>(
      endpoints.FUNDERS.CREATE_FUNDER,
      data
    );
    return response.data.data;
  }

  async updateFunder(data: IFunder, funderId: string) {
    const response = await axiosClient.patch<{ data: IFunder }>(
      formatString(endpoints.FUNDERS.UPDATE_FUNDER, funderId),
      data
    );
    return response.data.data;
  }

  async getFunder(funderId: string) {
    const response = await axiosClient.get<{ data: IFunder }>(
      formatString(endpoints.FUNDERS.SINGLE_FUNDER, funderId)
    );
    return response.data.data;
  }

  async deleteFunder(funderId: string) {
    const response = await axiosClient.delete<{ data: { deleted: boolean } }>(
      formatString(endpoints.FUNDERS.SINGLE_FUNDER, funderId)
    );
    return response;
  }

  async getFunders(queryParams: GetFundersPayload) {
    const response = await axiosClient.get<IPaginatedResponse<IFunder>>(
      endpoints.FUNDERS.GET_FUNDERS,
      {
        params: queryParams
      }
    );
    return response.data;
  }

  async approveFunder(funderId: string) {
    const response = await axiosClient.put<{ data: IFunder }>(
      formatString(endpoints.FUNDERS.APPROVE_FUNDER, funderId)
    );
    return response.data.data;
  }

  async getOffer(offerId: string) {
    const response = await axiosClient.get<{ data: IOffer }>(
      formatString(endpoints.OFFERS.SINGLE_OFFER, offerId)
    );
    return response.data.data;
  }

  async getFunderStatistics({ id, to, from }: Record<string, string>) {
    const response = await axiosClient.get<{ data: IFunderStatistic }>(
      formatString(endpoints.FUNDERS.GET_FUNDER_STATISTICS, id),
      { params: { from, to } }
    );

    return response.data.data;
  }

  async getFunderUsers(id: string, params: Record<string, unknown>) {
    const response = await axiosClient.get<IPaginatedResponse<IUser>>(
      formatString(endpoints.FUNDERS.GET_USERS, id),
      { params }
    );
    return response.data;
  }

  async createFunderUser(payload: Partial<IUser>) {
    const response = await axiosClient.post<{ data: IUser }>(
      endpoints.USERS.ALL,
      payload
    );
    return response.data.data;
  }

  async editFunderUser(id: string, data: Partial<IUser>) {
    await axiosClient.patch(formatString(endpoints.USERS.SINGLE, id), data);
  }
}

const fundersApiService = new FundersApiService();
export default fundersApiService;
