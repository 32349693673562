import fundersApiService from "@/services/modules/funders";
import usersApiService from "@/services/modules/users";
import router from "@/router";
import {
  FUNDER_ADMIN,
  PRODUCT_TYPE_ARLOC,
  PRODUCT_TYPE_EQUIPMENT_FINANCING,
  PRODUCT_TYPE_LINE_OF_CREDIT,
  PRODUCT_TYPE_RECEIVABLE_PURCHASE,
  PRODUCT_TYPE_TERM_LOAN
} from "@/helpers/constants";
import { FUNDER_STATISTICS, getTrend } from "@/helpers/statistics";
import {
  formatPayloadWithCurrency,
  addSuffix,
  formatTerms,
  phoneToE164
} from "@/helpers/formatting";

import type {
  IFunder,
  IOffer,
  IFunderPlacement,
  IFunderStatistic,
  IFunderMetric,
  IStatisticsData,
  IStatistics,
  IFunderOffersByType,
  GetFundersPayload
} from "@/models/funders";
import type { IFundersState } from "@/models/funders";
import type { IUser } from "@/models/users";
import type { IRootState } from "@/models/state";
import type { ActionTree, GetterTree, MutationTree } from "vuex";
import type {
  IPaginatedResponse,
  IResponseLinks,
  IResponseMeta
} from "@/models/common";
import type { IAuthUser } from "@/models/authentications";
import applicationsApiService from "@/services/modules/applications";

const getDefaultState = (): IFundersState => {
  return {
    meta: {} as IResponseMeta,
    all: [],
    active: {} as IFunder,
    submissions: {
      meta: {} as IResponseMeta,
      data: [],
      links: {} as IResponseLinks
    },
    funderOffers: {
      meta: {} as IResponseMeta,
      data: [],
      links: {} as IResponseLinks
    },
    funderOffersByType: {} as IFunderOffersByType,
    activeOffer: {} as IOffer,
    funderUsers: {
      meta: {} as IResponseMeta,
      data: [],
      links: {} as IResponseLinks
    },
    funderStatistics: {} as IFunderStatistic,
    hasFunderAdmin: false
  };
};

const state = getDefaultState();

const mutations: MutationTree<IFundersState> = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setAll(state, funders: IFunder[]) {
    state.all = funders;
  },
  setMetadata(state, meta: IResponseMeta) {
    state.meta = meta;
  },
  setActive(state, funder: IFunder) {
    state.active = funder;
  },
  unsetActive(state) {
    state.active = {} as IFunder;
  },
  addFunder(state, funder: IFunder) {
    const funders = [funder, ...state.all];
    state.all = funders;
  },
  setSubmissions(state, submissions: IPaginatedResponse<IFunderPlacement>) {
    state.submissions = submissions;
  },
  unsetSubmissions(state) {
    state.submissions = getDefaultState().submissions;
  },
  resetSelectedSubmissionType(state) {
    state.all = state.all.map((funder) => {
      if (funder.selected_method) {
        return { ...funder, selected_method: undefined };
      }
      return funder;
    });
  },
  setOffers(state, offers: IPaginatedResponse<IOffer>) {
    const withTermLength = {
      ...offers,
      data: offers.data.map((offer: IOffer) => ({
        ...offer,
        termLength: (formatTerms(offer) as string) || null
      }))
    };
    state.funderOffers = withTermLength;
  },
  setOffersByType(state, offers: IPaginatedResponse<IOffer>) {
    const offerType = offers.data.length
      ? offers.data[0].placement.product_id
      : null;
    if (offerType) {
      state.funderOffersByType[offerType] = offers;
    }
  },
  unsetOffersByType(state, id: number) {
    state.funderOffersByType = {
      ...state.funderOffersByType,
      [id]: {
        meta: {} as IResponseMeta,
        data: [],
        links: {} as IResponseLinks
      }
    };
  },
  setActiveOffer(state, offer: IOffer) {
    state.activeOffer = offer;
  },
  unsetActiveOffer(state) {
    state.activeOffer = {} as IOffer;
  },
  setFunderUsers(state, funderUsers: IPaginatedResponse<IUser>) {
    state.funderUsers = funderUsers;
  },
  unsetFunderUsers(state) {
    state.funderUsers = getDefaultState().funderUsers;
  },
  setHasFunderAdmin(state, val: boolean) {
    state.hasFunderAdmin = val;
  },
  setStatistics(state, statistics: IFunderStatistic) {
    state.funderStatistics = statistics;
  },
  unsetStatistics(state) {
    state.funderStatistics = getDefaultState().funderStatistics;
  },
  setFunderMetrics(state, statistics: IFunderStatistic) {
    Object.keys(FUNDER_STATISTICS.FUNDER_METRICS).forEach(
      (metric) =>
        (state.funderStatistics.general[metric] = statistics.general[metric])
    );
  },
  setConversionRates(state, statistics: IFunderStatistic) {
    Object.keys(FUNDER_STATISTICS.CONVERSION_RATES).forEach(
      (rate) =>
        (state.funderStatistics.general[rate] = statistics.general[rate])
    );
  },
  setProductStatistics(state, statistics: IFunderStatistic) {
    state.funderStatistics.products = statistics.products;
  }
};

const actions: ActionTree<IFundersState, IRootState> = {
  async create({ commit }, payload: Partial<IFunder>) {
    const funder = await fundersApiService.createFunder(
      formatPayloadWithCurrency(payload as Record<string, string | number>)
    );
    commit("addFunder", funder);
    commit("setActive", funder);
    return funder;
  },
  async getAll({ commit }, payload: GetFundersPayload) {
    const data = await fundersApiService.getFunders(payload);
    commit("setMetadata", data.meta);
    commit("setAll", data.data);
  },
  async find({ commit }, funderId: string) {
    const data = await fundersApiService.getFunder(funderId);
    return commit("setActive", data);
  },
  async findAuthFunder({ commit, rootState, dispatch }) {
    let authFunderId = rootState.auth?.user?.funder?.id;
    if (!authFunderId) {
      const userData = await (dispatch("auth/getAuthDetails", null, {
        root: true
      }) as Promise<IAuthUser>);
      if (!userData.funder?.id) {
        return;
      }
      authFunderId = userData.funder.id;
    }
    const data = await fundersApiService.getFunder(`${authFunderId}`);
    return commit("setActive", data);
  },
  async updateActive({ commit }, funder: IFunder) {
    const payload = formatPayloadWithCurrency(
      funder as unknown as Record<string, string | number>
    ) as unknown as IFunder;

    const updatedFunder = await fundersApiService.updateFunder(
      payload,
      state.active.id
    );
    return commit("setActive", updatedFunder);
  },
  async delete(_, funderId: string) {
    try {
      const { status } = await fundersApiService.deleteFunder(funderId);
      if (status === 204) {
        void router.push("/funders");
      }
    } catch (error) {
      //
    }
  },
  async approve({ commit }, funderId: string) {
    const updatedFunder = await fundersApiService.approveFunder(funderId);
    commit("setActive", updatedFunder);
  },
  async getOffer({ commit }, offerId: string) {
    const data = await fundersApiService.getOffer(offerId);
    commit("setActiveOffer", data);
    return data;
  },
  async getStatistics({ commit }, params: Record<string, string>) {
    const statistics = await fundersApiService.getFunderStatistics(params);

    switch (params.type) {
      case "metrics":
        commit("setFunderMetrics", statistics);
        break;
      case "rates":
        commit("setConversionRates", statistics);
        break;
      case "products":
        commit("setProductStatistics", statistics);
        break;
      default:
        commit("setStatistics", statistics);
    }
    return statistics;
  },
  async getUsers({ commit, rootState }, query: Record<string, unknown> = {}) {
    const currentRoute = router.currentRoute.value;
    const funderId = rootState.auth.user?.funder?.id;
    if (!(currentRoute || rootState.auth.user?.funder)) {
      return {};
    }

    const correctId =
      currentRoute.name &&
      ["FunderOrganization", "Funder-Details"].includes(
        currentRoute.name.toString()
      )
        ? funderId
        : (currentRoute.params.id as string);

    if (!correctId) {
      return;
    }

    const funderUsers = await fundersApiService.getFunderUsers(
      `${correctId}`,
      query
    );

    commit("setFunderUsers", funderUsers);
    return funderUsers;
  },
  async checkHasFunderAdmin({ dispatch, commit }) {
    const { data } = (await dispatch("getUsers", {
      per_page: 100
    })) as IPaginatedResponse<IUser>;

    const hasFa = data.some((user) => user.roles.includes(FUNDER_ADMIN));
    commit("setHasFunderAdmin", hasFa);
  },
  async createUser({ dispatch }, payload: Partial<IUser>) {
    await fundersApiService.createFunderUser(payload);
    await (dispatch("getUsers") as Promise<void>);
  },
  async editUser(
    { dispatch },
    payload: { userId: string; data: Partial<IUser> }
  ) {
    await fundersApiService.editFunderUser(payload.userId, payload.data);
    await (dispatch("getUsers") as Promise<void>);
  },
  async deleteUser({ dispatch }, { userId }: { userId: number }) {
    await usersApiService.deleteUser(userId);
    await (dispatch("getUsers") as Promise<void>);
  },
  async getScorecardsResults(_, payload: { id: string; funderIds: string[] }) {
    const response = await applicationsApiService.getScorecardsResults(payload);
    return response;
  }
};

const getters: GetterTree<IFundersState, IRootState> = {
  active(state) {
    return state.active;
  },
  all(state) {
    return state.all;
  },
  meta(state) {
    return state.meta;
  },
  submissions(state) {
    return state.submissions;
  },
  funderUsers(state) {
    return {
      ...state.funderUsers,
      data: state.funderUsers.data.map((user) => ({
        ...user,
        phone: phoneToE164(user.phone)
      }))
    };
  },
  funderOffers(state) {
    return state.funderOffers;
  },
  funderOffersByType(state) {
    return state.funderOffersByType;
  },
  activeOffer(state) {
    return state.activeOffer;
  },
  hasFunderAdmin(state) {
    return state.hasFunderAdmin;
  },
  funderMetrics(state) {
    return generateMetrics(
      state.funderStatistics.general,
      FUNDER_STATISTICS.FUNDER_METRICS
    );
  },
  productMetrics(state) {
    if (!state.funderStatistics.products) return {};
    return {
      [PRODUCT_TYPE_ARLOC]: generateMetrics(
        state.funderStatistics.products[PRODUCT_TYPE_ARLOC],
        FUNDER_STATISTICS.PRODUCT_METRICS
      ),
      [PRODUCT_TYPE_LINE_OF_CREDIT]: generateMetrics(
        state.funderStatistics.products[PRODUCT_TYPE_LINE_OF_CREDIT],
        FUNDER_STATISTICS.PRODUCT_METRICS
      ),
      [PRODUCT_TYPE_RECEIVABLE_PURCHASE]: generateMetrics(
        state.funderStatistics.products[PRODUCT_TYPE_RECEIVABLE_PURCHASE],
        FUNDER_STATISTICS.PRODUCT_METRICS
      ),
      [PRODUCT_TYPE_TERM_LOAN]: generateMetrics(
        state.funderStatistics.products[PRODUCT_TYPE_TERM_LOAN],
        FUNDER_STATISTICS.PRODUCT_METRICS
      ),
      [PRODUCT_TYPE_EQUIPMENT_FINANCING]: generateMetrics(
        state.funderStatistics.products[PRODUCT_TYPE_EQUIPMENT_FINANCING],
        FUNDER_STATISTICS.PRODUCT_METRICS
      )
    };
  },
  conversionRates(state) {
    return generateMetrics(
      state.funderStatistics.general,
      FUNDER_STATISTICS.CONVERSION_RATES
    );
  }
};

export const funders = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};

function generateMetrics(
  rawStats: Record<string, number | IStatisticsData>,
  anotatedStats: Record<string, IFunderMetric>
) {
  if (!rawStats) {
    return [];
  }

  return Object.keys(rawStats)
    .map((stat) => {
      const anotatedStat = anotatedStats[stat];
      if (!anotatedStat) {
        return null;
      }
      let total;
      const rawTotal = (rawStats[stat] as IStatisticsData).total;

      total = anotatedStat.formatter?.(rawTotal);

      if (anotatedStat.suffix) {
        total = addSuffix(total || rawTotal, anotatedStat.suffix);
      }
      const data = Object.values(
        (rawStats[stat] as unknown as IStatistics).data
      );

      return {
        ...anotatedStat,
        total: total || rawTotal,
        trend: getTrend(data),
        data: Object.values(
          (rawStats[stat] as unknown as { data: IStatisticsData }).data
        )
      };
    })
    .filter(Boolean);
}
