import i18n from "@/i18n";
import type {
  IExperianBusinessMatchRequestEntityCA,
  IExperianBusinessMatchRequestEntityUS,
  IExperianBusinessMatchResponseEntityCA,
  IExperianBusinessMatchResponseEntityUS
} from "@/models/applications";
import type { BusinessCreditService } from "@/models/options";
import type { ActiveClientCredentialsValues } from "@/models/clients";
import {
  COMMERCIAL_DATA_STATUS_NOT_STARTED,
  COMMERCIAL_DATA_STATUS_STARTED,
  COMMERCIAL_DATA_STATUS_SUCCESS,
  PROVIDER_IMAGES,
  NO_BUSINESS_FOUND_MESSAGE,
  NO_MATCH_FOUND_MESSAGE,
  NOT_STARTED_OR_IN_PROGRESS,
  NO_RESULTS_FOUND_MESSAGE,
  NO_SEARCH_RESULTS_FOUND_MESSAGE
} from "@/helpers/constants";
import { formatAddress } from "@/helpers/formatting";
import store from "@/store";

const { t } = i18n.global;

const isServiceError = (error: string | undefined | null): boolean =>
  !!error &&
  ![
    NO_BUSINESS_FOUND_MESSAGE,
    NO_MATCH_FOUND_MESSAGE,
    NO_RESULTS_FOUND_MESSAGE
  ].some((message) => error?.toLowerCase().includes(message));

export const serviceIsStarted = (status: string) =>
  !status.includes(COMMERCIAL_DATA_STATUS_NOT_STARTED);

export const serviceHasError = (status: string | null | undefined): boolean => {
  if (!status) {
    return false;
  }

  const isExpectedMessage = [
    COMMERCIAL_DATA_STATUS_NOT_STARTED,
    COMMERCIAL_DATA_STATUS_STARTED,
    COMMERCIAL_DATA_STATUS_SUCCESS
  ].some((value) => value.toLowerCase() === status.toLowerCase());

  if (isExpectedMessage) {
    return false;
  }

  return isServiceError(status?.toLowerCase());
};

export const serviceHasUnexpectedStatus = (
  status: string | null | undefined
): boolean => {
  if (!status) {
    return false;
  }

  const isExpectedMessage = [
    COMMERCIAL_DATA_STATUS_NOT_STARTED,
    COMMERCIAL_DATA_STATUS_STARTED,
    COMMERCIAL_DATA_STATUS_SUCCESS,
    NO_BUSINESS_FOUND_MESSAGE,
    NO_MATCH_FOUND_MESSAGE,
    NO_RESULTS_FOUND_MESSAGE,
    NO_SEARCH_RESULTS_FOUND_MESSAGE
  ].some(
    (value) =>
      value.toLowerCase() === status.toLowerCase() ||
      status.toLowerCase().includes(value.toLowerCase())
  );

  return !isExpectedMessage;
};

export const getApiFailedActionName = (
  haveResponse: boolean | null | undefined
): string => (haveResponse ? t("COMMON.RESTART") : t("COMMON.RUN"));

const getProviderImageByValue = (
  key: "provider_id" | "filename" | "service",
  value: string | number | null | undefined
) => {
  if (!value) {
    return undefined;
  }

  const providerImage = PROVIDER_IMAGES.find((pi) =>
    key === "service"
      ? String(value).toLowerCase().includes(pi[key].toLowerCase())
      : pi[key] === value
  );

  if (!providerImage) {
    return undefined;
  }

  return `/images/${providerImage.filename}`;
};

export const getProviderImageByServiceName = (serviceName: string) =>
  getProviderImageByValue("service", serviceName);

export const getImageForService = (
  serviceId: number | string | null | undefined
) => getProviderImageByValue("provider_id", serviceId);

export const getImageForServiceByFilename = (filename: string | null) =>
  getProviderImageByValue("filename", filename);

export const serviceHasBeenRun = (...args: (string | undefined)[]) =>
  args.some(
    (arg) =>
      !NOT_STARTED_OR_IN_PROGRESS.includes(
        arg as (typeof NOT_STARTED_OR_IN_PROGRESS)[number]
      )
  );

export const checkIsResponseCanadian = (
  model:
    | IExperianBusinessMatchResponseEntityUS
    | IExperianBusinessMatchResponseEntityCA
): model is IExperianBusinessMatchResponseEntityCA => {
  return "Phone" in model;
};

export const checkIsRequestCanadian = (
  model:
    | IExperianBusinessMatchRequestEntityUS
    | IExperianBusinessMatchRequestEntityCA
): model is IExperianBusinessMatchRequestEntityCA => {
  return "postcode" in model;
};

export const formatedAddress = (
  candidate:
    | IExperianBusinessMatchResponseEntityUS
    | IExperianBusinessMatchResponseEntityCA
    | null
) => {
  if (!candidate?.address) {
    return "-";
  }

  const baseAddress: Record<string, string> = {
    address_line: candidate?.address.street || "",
    city: candidate?.address.city || ""
  };

  if (checkIsResponseCanadian(candidate)) {
    (baseAddress.state = candidate.address.area || ""),
      (baseAddress.zip = candidate.address.postcode || "");
  } else {
    (baseAddress.state = candidate.address.state || ""),
      (baseAddress.zip = candidate.address.zip || "");
  }

  return formatAddress(baseAddress);
};

export const transformServiceNameToExpectedString = (
  serviceName: string | null
): BusinessCreditService | null => {
  if (!serviceName) {
    return null;
  }
  const fragmentIncluded = (fragment: string) => serviceName.includes(fragment);
  if (fragmentIncluded("clear_risk_inform_business")) {
    return "clear_risk_inform_business_search";
  }
  if (fragmentIncluded("clear_risk_inform_person")) {
    return "clear_risk_inform_person_search";
  }
  if (serviceName === "clear_id_confirm_person_match") {
    return "clear_id_confirm_person";
  }
  if (fragmentIncluded("clear_court_search")) {
    return "clear_court_search";
  }
  if (
    fragmentIncluded("cer_l1") ||
    fragmentIncluded("ci_l2") ||
    fragmentIncluded("pi_l3") ||
    fragmentIncluded("fi_l2") ||
    fragmentIncluded("fi_l3") ||
    fragmentIncluded("fi_l4") ||
    fragmentIncluded("dti_l1") ||
    fragmentIncluded("bm_l1")
  ) {
    return (
      fragmentIncluded("dnb_") ? serviceName : `dnb_${serviceName}`
    ) as BusinessCreditService;
  }
  if (serviceName === "experian_facts") {
    return "experian_business_facts";
  }
  if (fragmentIncluded("intelliscore") || fragmentIncluded("fsr")) {
    return `experian_${serviceName}` as BusinessCreditService;
  }
  if (fragmentIncluded("middesk")) {
    return "middesk";
  }
  return serviceName as BusinessCreditService;
};

export const showStatusMessage = (status: string | null | undefined) => {
  return (
    !serviceHasUnexpectedStatus(status) &&
    ![
      COMMERCIAL_DATA_STATUS_SUCCESS,
      COMMERCIAL_DATA_STATUS_NOT_STARTED
    ].includes(status || "")
  );
};

export const haveValuesForClientCredentials = (key: string) => {
  const byocValues: ActiveClientCredentialsValues =
    store.getters["clients/activeClientCredentialsValues"];
  const service = byocValues?.data?.find(
    ({ service_name }) => service_name === key
  );

  if (!service) {
    return false;
  }

  return service.is_active && Object.values(service).some(Boolean);
};

export const getValuesForService = (key: string) => {
  const byocValues: ActiveClientCredentialsValues =
    store.getters["clients/activeClientCredentialsValues"];
  return (
    byocValues?.data?.find((values) => values.service_name === key) ?? undefined
  );
};

export const getValuesForMultiTabService = (keys: string[]) => {
  const byocValues: ActiveClientCredentialsValues | null =
    store.getters["clients/activeClientCredentialsValues"];
  return (
    byocValues?.data?.filter((values) => keys.includes(values.service_name)) ||
    []
  );
};

export const getIsCfaSummary = (accountNumber: string) =>
  accountNumber === t("DEALS.DEAL_PROGRESS.BANK_CONNECTION.SUMMARY");
