<template>
  <lf-modal
    :close="close"
    :title="
      $t('COMMON.EDIT') +
      ' ' +
      $t(
        'DEALS.DEAL_PROGRESS.AFFILIATED_BUSINESSES_INFO.ADDITIONAL_INFO.TITLE',
        1
      )
    "
  >
    <template v-slot:content>
      <form novalidate @submit="submit">
        <div class="p-6 space-y-5">
          <div
            v-if="
              [
                AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.fiscal_year_end,
                AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.naics_code
              ].some(isFieldVisible)
            "
            class="flex flex-row flex-wrap space-x-0 sm:space-x-5"
          >
            <lf-dropdown
              v-if="
                isFieldVisible(
                  AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.fiscal_year_end
                )
              "
              :name="
                AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.fiscal_year_end
              "
              :placeholder="
                $t('DEALS.DEAL_PROGRESS.BUSINESS_INFO.FISCAL_YEAR_END')
              "
              :options="FULL_MONTHS"
              full-width
            />
            <lf-input
              v-if="
                isFieldVisible(
                  AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.naics_code
                )
              "
              :name="AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.naics_code"
              :placeholder="$t('DEALS.DEAL_PROGRESS.BUSINESS_INFO.NAICS')"
              noMargin
            />
          </div>
          <div
            v-if="
              [
                AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.duns_number,
                AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.experian_bin
              ].some(isFieldVisible)
            "
            class="flex flex-wrap space-x-0 sm:space-x-5"
          >
            <lf-input
              v-if="
                isFieldVisible(
                  AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.duns_number
                )
              "
              :placeholder="$t('DEALS.DEAL_PROGRESS.BUSINESS_INFO.DUNS')"
              :name="AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.duns_number"
              noMargin
            />
            <lf-input
              v-if="
                isFieldVisible(
                  AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.experian_bin
                )
              "
              :placeholder="$t('DEALS.DEAL_PROGRESS.BUSINESS_INFO.BIN')"
              :name="
                AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.experian_bin
              "
              noMargin
            />
          </div>
          <div
            v-if="
              [
                AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.sub_industry_type_id,
                AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.industry_type_id
              ].some(isFieldVisible)
            "
            class="flex flex-row flex-wrap space-x-0 sm:space-x-5"
          >
            <lf-dropdown
              v-if="
                isFieldVisible(
                  AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.industry_type_id
                )
              "
              :name="
                AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.industry_type_id
              "
              :placeholder="
                $t('DEALS.DEAL_PROGRESS.BUSINESS_INFO.INDUSTRY_TYPE')
              "
              :options="industryTypesOptions"
              sort
              clear-filter
              search-enabled
              full-width
              @change="onIndustryTypeChange"
            />

            <div class="flex-1">
              <lf-dropdown
                v-if="
                  isFieldVisible(
                    AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.sub_industry_type_id
                  )
                "
                :key="
                  AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.sub_industry_type_id
                "
                :name="
                  AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.sub_industry_type_id
                "
                :disabled="
                  !values[
                    AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS
                      .industry_type_id
                  ]
                "
                :placeholder="
                  $t('DEALS.DEAL_PROGRESS.BUSINESS_INFO.SUB_INDUSTRY')
                "
                :options="subIndustryTypesOptions(selectedIndustryId)"
                sort
              />
            </div>
          </div>
          <div
            v-if="
              isFieldVisible(
                AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.industry_details
              )
            "
            class="flex flex-row flex-wrap space-x-0 sm:space-x-5 mt-5"
          >
            <lf-input
              v-if="
                isFieldVisible(
                  AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.industry_details
                )
              "
              :placeholder="
                $t('DEALS.DEAL_PROGRESS.BUSINESS_INFO.INDUSTRY_OTHER')
              "
              :name="
                AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.industry_details
              "
              noMargin
            />
            <div class="flex-1" />
          </div>
          <div
            v-if="
              [
                AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.number_of_employees_id,
                AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.sic_code
              ].some(isFieldVisible)
            "
            class="flex flex-row flex-wrap space-x-0 sm:space-x-5"
          >
            <lf-dropdown
              v-if="
                isFieldVisible(
                  AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.number_of_employees_id
                )
              "
              :name="
                AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.number_of_employees_id
              "
              :placeholder="
                $t('DEALS.DEAL_PROGRESS.BUSINESS_INFO.NO_OF_EMPLOYEES')
              "
              :options="numberOfEmployeesOptionsLabels"
            />
            <lf-input
              v-if="
                isFieldVisible(
                  AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.sic_code
                )
              "
              :name="AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.sic_code"
              :placeholder="
                $t(
                  'BUSINESS_PROFILES.BUSINESS.ADDITIONAL_BUSINESS_INFO.SIC_CODE'
                )
              "
              noMargin
            />
          </div>

          <div
            v-if="
              [
                AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.business_management_software,
                AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.website_address
              ].some(isFieldVisible)
            "
            class="flex flex-row flex-wrap space-x-0 sm:space-x-5"
          >
            <lf-input
              v-if="
                isFieldVisible(
                  AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.business_management_software
                )
              "
              :name="
                AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.business_management_software
              "
              :placeholder="
                $t('DEALS.DEAL_PROGRESS.BUSINESS_INFO.HAS_ACCOUNTING_SOFTWARE')
              "
              noMargin
            />
            <lf-input
              v-if="
                isFieldVisible(
                  AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.website_address
                )
              "
              :name="
                AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.website_address
              "
              :placeholder="$t('DEALS.DEAL_PROGRESS.BUSINESS_INFO.WEBSITE')"
              type="url"
              noMargin
              @change="handleWebsiteAddressChange"
            />
          </div>
          <div
            v-if="
              [
                AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.w2_number_of_employees_2020,
                AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.w2_number_of_employees_2021
              ].some(isFieldVisible)
            "
            class="flex flex-row flex-wrap space-x-0 sm:space-x-5"
          >
            <lf-input
              v-if="
                isFieldVisible(
                  AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.w2_number_of_employees_2020
                )
              "
              :name="
                AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.w2_number_of_employees_2020
              "
              :placeholder="
                $t('DEALS.DEAL_PROGRESS.BUSINESS_INFO.W2_EMPLOYEES_2020')
              "
              noMargin
            />
            <lf-input
              v-if="
                isFieldVisible(
                  AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.w2_number_of_employees_2021
                )
              "
              :name="
                AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.w2_number_of_employees_2021
              "
              :placeholder="
                $t('DEALS.DEAL_PROGRESS.BUSINESS_INFO.W2_EMPLOYEES_2021')
              "
              noMargin
            />
          </div>
          <div
            class="flex flex-row flex-wrap space-x-0 sm:space-x-5"
            v-if="
              [
                AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.current_business_debt,
                AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.custom_field
              ].some(isFieldVisible)
            "
          >
            <lf-currency
              v-if="
                isFieldVisible(
                  AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.current_business_debt
                )
              "
              :model-value="
                props.additionalAffiliatedBusinessInfo?.[
                  AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS
                    .current_business_debt
                ]
              "
              :name="
                AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.current_business_debt
              "
              :placeholder="
                $t('DEALS.DEAL_PROGRESS.BUSINESS_INFO.CURRENT_BUSINESS_DEBT')
              "
              noMargin
            />
            <lf-input
              v-if="
                isFieldVisible(
                  AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.custom_field
                )
              "
              :name="
                AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.custom_field
              "
              :placeholder="
                $t('DEALS.DEAL_PROGRESS.BUSINESS_INFO.CUSTOM_FIELD')
              "
              noMargin
            />
          </div>
        </div>

        <div class="border-t border-gray-200 p-6 flex justify-end">
          <outline-button
            @click="close"
            class="mr-3"
            data-cy="modal-bottom-close-button"
          >
            {{ $t("COMMON.CANCEL") }}
          </outline-button>
          <primary-button
            type="submit"
            :disabled="isSubmitting"
            role="saveBusiness"
          >
            {{ $t("COMMON.SAVE") }}
          </primary-button>
        </div>
      </form>
    </template>
  </lf-modal>
</template>
<script setup lang="ts">
import LfModal from "@/components/ui/Modal.vue";

import type { PropType } from "vue";
import { onBeforeMount, ref } from "vue";
import { useStore } from "vuex";
import { useForm } from "vee-validate";
import {
  filterNotNullableValues,
  forceNullForEmptyStrings,
  getDateFromMonth,
  looksLikeCurrency
} from "@/helpers/formatting";
import { dispatchAction } from "@/helpers/vee-validate";
import { FULL_MONTHS } from "@/helpers/constants";
import { looksLikeNumber } from "@/helpers/common";
import type { IBusiness } from "@/models/applications";
import { useIndustryOptions, useNumberOfEmployees } from "@/hooks/options";
import {
  AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS,
  WORKFLOW_BLOCKS_IDS
} from "@/helpers/constants/workflow";
import { useWorkflowFields } from "@/hooks/workflow";
import type { AdditionalAffiliatedBusinessInfoFields } from "@/models/workflows";
import { websiteAddressChange } from "@/helpers/workflow";

const props = defineProps({
  close: {
    type: Function as PropType<() => void>,
    required: true
  },
  additionalAffiliatedBusinessInfo: {
    type: Object as PropType<AdditionalAffiliatedBusinessInfoFields | null>,
    default: () => ({
      affiliated_businesses_additional_information_id: null,
      affiliated_businesses_additional_information_fiscal_year_end: "",
      affiliated_businesses_additional_information_industry_type: "",
      affiliated_businesses_additional_information_sub_industry_type: "",
      affiliated_businesses_additional_information_SIC_code: "",
      affiliated_businesses_additional_information_NAICS_code: "",
      affiliated_businesses_additional_information_website: "",
      affiliated_businesses_additional_information_number_of_employees: "",
      affiliated_businesses_additional_information_business_management_software:
        "",
      affiliated_businesses_additional_information_current_business_debt: null
    })
  },
  id: {
    type: Number,
    required: true
  }
});

const store = useStore();
const { industryTypesOptions, subIndustryTypesOptions } = useIndustryOptions();
const {
  numberOfEmployeesOptionsLabels,
  numberOfEmployeeIdByIndex,
  numberOfEmployeeIndexById
} = useNumberOfEmployees();
const { isFieldVisible } = useWorkflowFields(
  WORKFLOW_BLOCKS_IDS.affiliated_additional_businesses
);
const { handleSubmit, isSubmitting, setFieldValue, values } = useForm({
  initialValues: {
    ...props.additionalAffiliatedBusinessInfo,
    [AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.number_of_employees_id]:
      numberOfEmployeeIndexById(
        Number(
          props.additionalAffiliatedBusinessInfo?.[
            AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS
              .number_of_employees_id
          ]
        )
      )
  }
});

const selectedIndustryId = ref(0);
const business = ref<Partial<IBusiness>>({
  ...store.getters["applications/active"].business
});

const handleWebsiteAddressChange = (e: Event) => {
  setFieldValue(
    AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.website_address,
    websiteAddressChange(e)
  );
};

const onIndustryTypeChange = (industryId: string) => {
  business.value = {
    ...business.value,
    industry_type_id: Number(industryId),
    sub_industry_type_id: null
  };
  selectedIndustryId.value = Number(industryId);
  setFieldValue(
    AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.sub_industry_type_id,
    null
  );
};

const submit = handleSubmit(async (values, actions) => {
  if (
    looksLikeNumber(
      values.affiliated_businesses_additional_information_number_of_employees
    )
  ) {
    values.affiliated_businesses_additional_information_number_of_employees =
      numberOfEmployeeIdByIndex(
        Number(
          values.affiliated_businesses_additional_information_number_of_employees
        )
      );
  }
  values.affiliated_businesses_additional_information_id = props.id;

  const currencyValueFields: (keyof typeof values)[] = [
    AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.current_business_debt
  ];

  currencyValueFields.forEach((field) => {
    if (values[field] && looksLikeCurrency(String(values[field]))) {
      (values[field] as number) = Number(
        String(values[field]).replace(/[^\d.]/g, "")
      );
    }
  });

  values = filterNotNullableValues(values, [
    AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.naics_code,
    AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.fiscal_year_end
  ]);

  if (values?.affiliated_businesses_additional_information_fiscal_year_end) {
    values.affiliated_businesses_additional_information_fiscal_year_end =
      getDateFromMonth(
        values?.affiliated_businesses_additional_information_fiscal_year_end
      );
  }

  values = forceNullForEmptyStrings(values);

  await dispatchAction(
    {
      [WORKFLOW_BLOCKS_IDS.affiliated_additional_businesses]: [values]
    },
    actions,
    "workflows/updateWorkflowSnapshotFields"
  );
  props.close();
});

onBeforeMount(() => {
  selectedIndustryId.value = Number(
    props.additionalAffiliatedBusinessInfo?.[
      AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.industry_type_id
    ]
  );
});
</script>
