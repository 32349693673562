import type { LogEndpointType } from "@/enums/communicationLogs";
import { formatString } from "@/helpers/formatting";
import type { IApplication } from "@/models/applications";
import type {
  CallOutPayload,
  CommunicationLogResponse,
  CommunicationFilters,
  Task,
  CallNotePayload,
  CallLogData,
  CallLog,
  TaskLogData,
  CommunicationLogBase,
  CommunicationLogComment,
  CreateTaskPayload
} from "@/models/communicationLogs";
import { axiosClient } from "@/services/client";
import endpoints from "@/services/endpoints";
import store from "@/store";

class CommunicationService {
  private get isCommunicationIndexed() {
    const options = store.state.options.dashboardSettings;
    return !!options?.communicationIndexed;
  }

  async callOut(
    applicationId: IApplication["id"],
    payload: CallOutPayload
  ): Promise<CallLog> {
    const response = await axiosClient.post(
      formatString(endpoints.COMMUNICATION.CALL_OUT, applicationId),
      payload
    );
    return response.data;
  }

  async getCommunicationList(params: Partial<CommunicationFilters> = {}) {
    const url = this.isCommunicationIndexed
      ? endpoints.COMMUNICATION.LOGS.ALL_INDEXED
      : endpoints.COMMUNICATION.LOGS.ALL;
    const response = await axiosClient.get<CommunicationLogResponse>(url, {
      params
    });
    return response.data;
  }

  async getCommunicationLog(logId: CommunicationLogBase["id"]) {
    const url = formatString(endpoints.COMMUNICATION.LOGS.SINGLE, logId);
    const response = await axiosClient.get<CommunicationLogBase>(url);
    return response.data;
  }

  async createTask(payload: CreateTaskPayload) {
    const response = await axiosClient.post<{ data: Task }>(
      formatString(endpoints.COMMUNICATION.TASKS.CREATE),
      payload
    );
    return response.data.data;
  }

  async updateTask(taskId: Task["id"], payload: Partial<TaskLogData>) {
    const response = await axiosClient.patch<{ data: Task }>(
      formatString(endpoints.COMMUNICATION.TASKS.UPDATE, taskId),
      payload
    );
    return response.data.data;
  }

  async updateCallNote(logId: CallLogData["id"], payload: CallNotePayload) {
    const response = await axiosClient.put<{ data: CallLog }>(
      formatString(endpoints.COMMUNICATION.CALL_LOGS.UPDATE, logId),
      payload
    );
    return response.data.data;
  }

  async deleteTask(taskId: Task["id"]) {
    const response = await axiosClient.delete(
      formatString(endpoints.COMMUNICATION.TASKS.UPDATE, taskId)
    );
    return response.data.data;
  }

  async createComment(
    type: LogEndpointType,
    logId: CommunicationLogBase["id"],
    comment: string
  ) {
    const endpoint = formatString(
      endpoints.COMMUNICATION.COMMENTS.ALL,
      type,
      logId
    );
    const response = await axiosClient.post<{ data: CommunicationLogComment }>(
      endpoint,
      { comment }
    );
    return response.data.data;
  }

  async updateComment(
    type: LogEndpointType,
    logId: CommunicationLogBase["id"],
    commentId: CommunicationLogComment["id"],
    comment: string
  ) {
    const endpoint = formatString(
      endpoints.COMMUNICATION.COMMENTS.SINGLE,
      type,
      logId,
      commentId
    );
    const response = await axiosClient.put<{ data: CommunicationLogComment }>(
      endpoint,
      { comment }
    );
    return response.data.data;
  }

  async deleteComment(
    type: LogEndpointType,
    logId: CommunicationLogBase["id"],
    commentId: CommunicationLogComment["id"]
  ) {
    const endpoint = formatString(
      endpoints.COMMUNICATION.COMMENTS.SINGLE,
      type,
      logId,
      commentId
    );
    return await axiosClient.delete(endpoint);
  }
}

const communicationService = new CommunicationService();
export default communicationService;
