<template>
  <lf-card class="py-6">
    <template v-slot:header>
      <lf-h3>
        {{
          $t(
            "DEALS.DEAL_PROGRESS.AFFILIATED_BUSINESSES_INFO.ADDITIONAL_INFO.TITLE",
            2
          )
        }}
      </lf-h3>

      <button
        v-if="canEditBusinessInfo && selectedAdditionalAffiliatedBusiness"
        @click.stop="isModalOpen = true"
        data-cy="edit-additional-affiliated-business-info"
        class="cursor-pointer"
      >
        <icon-base
          icon="edit"
          class="mr-2-5"
          :icon-color="UTIL_COLORS.DEFAULT"
        />
        {{ $t("COMMON.EDIT") }}
      </button>
    </template>
    <template #default>
      <div
        v-if="additiontalAffiliatedBusinesses.length"
        class="grid grid-cols-3 gap-5 -mt-3"
      >
        <div v-for="field in FIELDS_DETAILS" :key="field.id">
          <workflow-field
            :label="field.title"
            :value="field.value"
            :id="field.id"
            click-to-copy
          />
        </div>
      </div>
      <div v-else>
        {{
          $t(
            "DEALS.DEAL_PROGRESS.AFFILIATED_BUSINESSES_INFO.ADDITIONAL_INFO.NO_INFO"
          )
        }}
      </div>
    </template>
  </lf-card>
  <additional-affiliated-business-info-modal
    v-if="!readonly && isModalOpen && selectedAdditionalAffiliatedBusinessId"
    :close="() => (isModalOpen = false)"
    :additional-affiliated-businessInfo="selectedAdditionalAffiliatedBusiness"
    :id="selectedAdditionalAffiliatedBusinessId"
  />
</template>
<script setup lang="ts">
import { computed, ref } from "vue";
import { useStore } from "vuex";
import type { IRootState } from "@/models/state";
import { useDeals } from "@/hooks/deals";
import { useAuth } from "@/hooks/auth";
import AdditionalAffiliatedBusinessInfoModal from "@/views/deals/modals/AdditionalAffiliatedBusinessInfoModal.vue";
import { useWorkflowFields } from "@/hooks/workflow";
import {
  WORKFLOW_BLOCKS_IDS,
  AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS
} from "@/helpers/constants/workflow";
import { UTIL_COLORS } from "@/helpers/constants";
import type { AdditionalAffiliatedBusinessInfoFields } from "@/models/workflows";
import { useI18n } from "vue-i18n";
import { FULL_MONTHS } from "@/helpers/constants";
import WorkflowField from "@/components/deals/WorkflowLive/WorkflowLiveField.vue";
import { useIndustryOptions } from "@/hooks/options";
import { formatMoney } from "@/helpers/formatting";

defineProps({
  readonly: {
    type: Boolean,
    default: false
  }
});

const { t } = useI18n();
const { getters } = useStore<IRootState>();
const { canEditDealProgress } = useDeals();
const { isFunder, isClient } = useAuth();
const { isFieldVisible, fieldData: additiontalAffiliatedBusinesses } =
  useWorkflowFields(WORKFLOW_BLOCKS_IDS.affiliated_additional_businesses);

const isModalOpen = ref(false);

const { subIndustryTypeLabel, industryTypeLabel } = useIndustryOptions();

const selectedAdditionalAffiliatedBusinessId = computed<number | null>(
  () => getters["applications/selectedAffiliatedBusinessId"]
);

const selectedAdditionalAffiliatedBusiness = computed<
  AdditionalAffiliatedBusinessInfoFields | undefined
>(() =>
  additiontalAffiliatedBusinesses.value.find(
    (additionalAffBusiness: AdditionalAffiliatedBusinessInfoFields) =>
      additionalAffBusiness.affiliated_businesses_additional_information_id ===
      selectedAdditionalAffiliatedBusinessId.value
  )
);

const numberOfEmployees = computed(() => {
  return selectedAdditionalAffiliatedBusiness.value?.[
    AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.number_of_employees_id
  ]
    ? getters["options/numberOfEmployees"](
        selectedAdditionalAffiliatedBusiness.value?.[
          AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.number_of_employees_id
        ]
      )
    : "-";
});

const industryLable = computed(() =>
  industryTypeLabel(
    selectedAdditionalAffiliatedBusiness.value?.[
      AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.industry_type_id
    ] || null
  )
);

const subIndustryLable = computed(() =>
  subIndustryTypeLabel(
    selectedAdditionalAffiliatedBusiness.value?.[
      AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.industry_type_id
    ] || null,
    selectedAdditionalAffiliatedBusiness.value?.[
      AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.sub_industry_type_id
    ] || null
  )
);

const current_business_debt = computed((): string => {
  const debt =
    selectedAdditionalAffiliatedBusiness.value?.[
      AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.current_business_debt
    ];
  return debt ? formatMoney(debt, 2) : "-";
});

const FIELDS_DETAILS = computed(() =>
  [
    {
      id: AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.fiscal_year_end,
      title: t("DEALS.DEAL_PROGRESS.BUSINESS_INFO.FISCAL_YEAR_END"),
      value: selectedAdditionalAffiliatedBusiness.value
        ?.affiliated_businesses_additional_information_fiscal_year_end
        ? FULL_MONTHS[
            selectedAdditionalAffiliatedBusiness.value
              ?.affiliated_businesses_additional_information_fiscal_year_end
          ]
        : "-"
    },
    {
      id: AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.industry_type_id,
      title: t("DEALS.DEAL_PROGRESS.BUSINESS_INFO.INDUSTRY_TYPE"),
      value: industryLable.value
    },
    {
      id: AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.sub_industry_type_id,
      title: t("DEALS.DEAL_PROGRESS.BUSINESS_INFO.SUB_INDUSTRY"),
      value: subIndustryLable.value
    },
    {
      id: AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.industry_details,
      title: t("DEALS.DEAL_PROGRESS.BUSINESS_INFO.INDUSTRY_OTHER"),
      value:
        selectedAdditionalAffiliatedBusiness.value
          ?.affiliated_businesses_additional_information_industry_other
    },
    {
      id: AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.sic_code,
      title: t("BUSINESS_PROFILES.BUSINESS.ADDITIONAL_BUSINESS_INFO.SIC_CODE"),
      value:
        selectedAdditionalAffiliatedBusiness.value
          ?.affiliated_businesses_additional_information_SIC_code
    },
    {
      id: AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.naics_code,
      title: t("DEALS.DEAL_PROGRESS.BUSINESS_INFO.NAICS"),
      value: naicsCodeWithName.value
    },
    {
      id: AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.duns_number,
      title: t("DEALS.DEAL_PROGRESS.BUSINESS_INFO.DUNS"),
      value:
        selectedAdditionalAffiliatedBusiness.value
          ?.affiliated_businesses_additional_information_duns_number
    },
    {
      id: AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.experian_bin,
      title: t("DEALS.DEAL_PROGRESS.BUSINESS_INFO.BIN"),
      value:
        selectedAdditionalAffiliatedBusiness.value
          ?.affiliated_businesses_additional_information_experian_bin
    },
    {
      id: AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.website_address,
      title: t("DEALS.DEAL_PROGRESS.BUSINESS_INFO.WEBSITE"),
      value:
        selectedAdditionalAffiliatedBusiness.value
          ?.affiliated_businesses_additional_information_website
    },
    {
      id: AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.number_of_employees_id,
      title: t("DEALS.DEAL_PROGRESS.BUSINESS_INFO.NO_OF_EMPLOYEES"),
      value: numberOfEmployees.value
    },
    {
      id: AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.business_management_software,
      title: t("DEALS.DEAL_PROGRESS.BUSINESS_INFO.HAS_ACCOUNTING_SOFTWARE"),
      value:
        selectedAdditionalAffiliatedBusiness.value
          ?.affiliated_businesses_additional_information_business_management_software
    },
    {
      id: AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.w2_number_of_employees_2020,
      title: t("DEALS.DEAL_PROGRESS.BUSINESS_INFO.W2_EMPLOYEES_2020"),
      value:
        selectedAdditionalAffiliatedBusiness.value
          ?.affiliated_businesses_additional_information_w2_number_of_employees_2020
    },
    {
      id: AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.w2_number_of_employees_2021,
      title: t("DEALS.DEAL_PROGRESS.BUSINESS_INFO.W2_EMPLOYEES_2021"),
      value:
        selectedAdditionalAffiliatedBusiness.value
          ?.affiliated_businesses_additional_information_w2_number_of_employees_2021
    },
    {
      id: AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.current_business_debt,
      title: t("DEALS.DEAL_PROGRESS.BUSINESS_INFO.CURRENT_BUSINESS_DEBT"),
      value: current_business_debt.value
    },
    {
      id: AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS.custom_field,
      title: t("DEALS.DEAL_PROGRESS.BUSINESS_INFO.CUSTOM_FIELD"),
      value:
        selectedAdditionalAffiliatedBusiness.value
          ?.affiliated_businesses_additional_information_custom_field
    }
  ].filter((field) => field?.id && isFieldVisible(field.id))
);

const canEditBusinessInfo = computed(
  () => (!isFunder || isClient) && canEditDealProgress.value
);

const naicsCodeWithName = computed(() => {
  const naics_code =
    selectedAdditionalAffiliatedBusiness.value
      ?.affiliated_businesses_additional_information_NAICS_code;
  const naicsCodes: Record<string, string> = getters["options/naicsCodes"];
  const formattedValue =
    naicsCodes?.[naicsCodeResolver.value[naics_code ?? ""]] ||
    naicsCodes?.[naics_code ?? ""];
  if (formattedValue) {
    return `(${naics_code}) ${formattedValue}`;
  }
  return naics_code;
});

/*
This is done for naics codes that are ranges.
For example key from the BE is 31-33 and user can input 31, 32 or 33
and every one of those values have to fall into that range
so we need to create a dynamic resolver which looks like this:
{
  "31": "31-33",
  "32": "31-33",
  "33": "31-33"
}
*/
const naicsCodeResolver = computed(() => {
  const naicsKeys = Object.keys(
    (getters["options/naicsCodes"] as Record<string, string>) || {}
  );
  const resolver: Record<string, string> = {};

  naicsKeys.forEach((key) => {
    const item = key.split("-");
    if (item.length > 1) {
      const min = Number(item[0]);
      const max = Number(item[1]);
      for (let i = min; i <= max; i++) {
        resolver[`${i}`] = `${min}-${max}`;
      }
    }
  });

  return resolver;
});
</script>
