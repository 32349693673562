import cloneDeep from "lodash/cloneDeep";

import WORKFLOW_BLOCK_COMPONENTS, {
  type WorkflowBlockComponentsKey
} from "@/helpers/constants/WorkflowComponentMap";
import type {
  ApplicationStage,
  IWorkflow,
  OwnerWFL,
  WorkflowBlockIds
} from "@/models/workflows";

import UnknownBlock from "@/components/deals/WorkflowLive/UnknownBlock.vue";

export const getWorkflowComponent = (blockId: WorkflowBlockComponentsKey) =>
  WORKFLOW_BLOCK_COMPONENTS[blockId] ?? UnknownBlock;

export const websiteAddressChange = (e: Event) => {
  const pattern = /^((http|https):\/\/)/;
  const websiteAddress = (e.target as HTMLInputElement).value;

  if (websiteAddress !== "" && !pattern.test(websiteAddress)) {
    return `https://${websiteAddress}`;
  }

  return websiteAddress;
};

export const deletePiiMaskedFields = <T extends Record<string, unknown>>(
  obj: T,
  maskedFields: string[]
) => {
  maskedFields.forEach((value: keyof T) => {
    if (
      obj[value] &&
      typeof obj[value] === "string" &&
      !!(obj[value] as string).match(/^\*{5}/)
    ) {
      delete obj[value];
    }
  });
  return obj;
};

export const sortOwnersArray = (owners: Array<OwnerWFL>) =>
  cloneDeep(owners).sort((a, b) => {
    if (a.personal_is_primary) return -1;
    if (b.personal_is_primary) return 1;
    return 0;
  });

export const isPiiMaskedField = (value: string): boolean =>
  !!value.match(/^\*{5}/);

export const getValidBlockIds = (
  ids: WorkflowBlockIds[],
  stageType: ApplicationStage,
  workflowTemplate: IWorkflow
) => {
  const blocks = workflowTemplate.content?.stages
    ?.filter((stage) => stage.type === stageType)
    .flatMap((stage) => stage.tabs)
    .flatMap((tab) => tab?.blocks);
  const validIds = blocks?.map((block) => block?.id);
  return ids.reduce((acc: WorkflowBlockIds[], id) => {
    if (validIds?.includes(id)) {
      acc.push(id);
    }
    return acc;
  }, []);
};
